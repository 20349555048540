<template>
  <div>
    <Dialog
      :header="$t('contracts.viewContract')"
      v-model:visible="viewDialog"
      :draggable="false"
      :modal="true"
      :style="{ width: '60vw' }"
    >
      <div v-if="loading" class="flex justify-content-center">
        <ProgressSpinner />
      </div>
      <div v-else>
        <!-- <iframe :src="contractView" class="w-full" style="height: 50rem" frameborder="0"></iframe> -->
        <!-- <object :data="contractView" type="application/pdf" width="100%" style="height: 50rem;"></object> -->
        <RenderFile></RenderFile>
      </div>
      <template #footer>
        <Button
          id="acceptButton"
          :label="$t('global.close')"
          :class="'p-button-danger'"
          @click="close()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import * as PDFJSViewer from "pdfjs-dist/web/pdf_viewer";
// import {getDocument } from "pdfjs-dist/build/pdf.js";
import RenderFile from "../RenderFile"
export default {
  name: "View contract",
  components: {
    RenderFile
  },
  props: ["display", "contract"],
  emits: ["closeViewContract"],
  data() {
    return {
      viewDialog: false,
      loading: false
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      // console.log(getDocument);
      // console.log(PDFJSViewer);
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      }else {
        this.loading = true;
       await this.$store.dispatch("downloadFile", {data: {id: this.contract.fileId}, download: false});
       this.loading = false;
      }
    },
  },
  computed: {
    checkStatus() {
      return (
        this.contract.status != "accepted" && this.contract.status != "rejected"
      );
    },
    ...mapGetters(["checkRole","fileToView"])
  },
  methods: {
    close(val) {
      this.$emit("closeViewContract", val);
      window.URL.revokeObjectURL(this.fileToView);
    },
    async acceptRejectContract(accept) {
      let temp = {
        id: this.contract.id,
        applicationId: this.contract.id,
        salesPersonName: this.contract.salesPersonName,
        companyRepresentativeName: this.contract.companyRepresentativeName,
        status: accept ? "accepted" : "rejected",
        comments: this.contract.comments,
        version: this.contract.version,
      };
      await this.$store.dispatch("updateContract", temp);
      this.$notification(
        this.$t("contracts.updateContractStatus"),
        accept
          ? this.$t("contracts.contractAccepted")
          : this.$t("contracts.contractRejected")
      );
      this.$notification(
        this.$t("contracts.updateContractStatus"),
        this.$t("contracts.reloadTableContracts"),
        "info"
      );
      this.close(true);
    },
  },
};
</script>
